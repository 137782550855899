import React from 'react';
import {navigate} from 'gatsby-link';
import * as Ant from 'antd';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import URL_PATH from '../../UrlPath';
import LoginModal from '../../components/LoginModal';
import {BREAK_POINTS} from '../../domain/Constants';

const UiState = {
  valid_phone: 0,
  validating: 1,
  registering: 2,
  registered: 3,
};
const steps = ['驗證手機', '輸入手機驗證碼', '輸入密碼', '完成註冊'];

const COOL_DOWN_TIME = 120;

function RegisterPage(props) {
  const app = React.useContext(AppContext.Context);
  const [countdown, setCountdown] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [sendingOtp, setSendingOtp] = React.useState(false);
  const [uiState, setUiState] = React.useState(UiState.valid_phone);
  const [vid, setVid] = React.useState('');
  const [accessToken, setAccessToken] = React.useState('');
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    password2: '',
  });

  const _openLoginModal = React.useCallback(() => {
    AppActions.setModal(
      <LoginModal onClose={() => AppActions.setModal(null)} />,
    );
  }, []);

  const _handleRegister = React.useCallback(async () => {
    if (!values.password || !values.password2 || !values.email) {
      setError('請確認所有欄位都已填寫！');
      return;
    }
    if (values.password !== values.password2) {
      setError('確認密碼不一致！');
      return;
    }

    if (values.password.length < 8) {
      setError('密碼至少須為八碼！');
      return;
    }

    setLoading(true);
    try {
      let resp = await app.actions.register({
        password: values.password,
        accessToken,
        email: values.email,
      });
      setUiState(UiState.registered);
    } catch (err) {
      let errorStr = '';
      if (err.error === 'invalid_password') {
        errorStr = '密碼須為八碼以上！';
      } else if (err.error === 'invalid_email_schema') {
        errorStr = '請輸入正確的電子信箱';
      } else {
        errorStr = '註冊失敗！請聯繫客服'; // FIXME: 客服聯繫方式？
      }
      setError(errorStr);
    }
    setLoading(false);
  }, [values, accessToken]);

  const _validateOtp = React.useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      let resp = await app.actions.verifyOtp({vid, code});
      console.log('resp', resp);
      setAccessToken(resp.access_token);
      setUiState(UiState.registering);
    } catch (err) {
      let errorStr = '';
      if (err.error === 'otp_validation_error') {
        if (err.detail?.error === 'validation_expired') {
          errorStr = '驗證碼已過期，請重新寄送';
        } else {
          errorStr = '請輸入正確的驗證碼';
        }
      } else {
        errorStr = '驗證失敗！請聯繫客服'; // FIXME: 客服聯繫方式？
      }
      setError(errorStr);
    }
    setLoading(false);
  }, [vid, code]);

  let timer = React.useRef();

  React.useEffect(() => {
    if (countdown === null) {
      return;
    }
    timer = setTimeout(() => {
      if (countdown <= 0) {
        clearInterval(timer);
        setCountdown(null);
      } else {
        setCountdown(countdown - 1);
      }
    }, 1000);
  }, [countdown]);

  const _setTimer = React.useCallback(() => {
    if (timer) {
      clearInterval(timer);
    }

    setCountdown(COOL_DOWN_TIME);
  }, []);

  const _requestPhoneOTP = React.useCallback(async () => {
    setSendingOtp(true);
    setError('');
    try {
      let resp = await app.actions.preRegisterPhoneValidate(phone);
      _setTimer();
      setVid(resp.vid);
      setUiState(UiState.validating);
    } catch (err) {
      let errorStr = '';
      if (err.error === 'invalid_phone_number') {
        errorStr = '請確認手機號碼格式是否正確';
      } else if (err.error === 'user_exist_error') {
        errorStr = '此手機號碼已經註冊囉！';
      } else if (err.error === 'too_many_requests') {
        errorStr = '請稍候再嘗試';
      } else {
        errorStr = '無法發送驗證碼！請聯繫客服';
      }
      setError(errorStr);
    }
    setSendingOtp(false);
  }, [phone]);

  return (
    <Wrapper>
      <div className="container">
        <h3>註冊</h3>
        <Ant.Steps
          className="rev-steps"
          current={uiState}
          style={{marginBottom: 30}}>
          {steps.map((step) => (
            <Ant.Steps.Step key={step} title={step} />
          ))}
        </Ant.Steps>

        <div className="content">
          {(() => {
            switch (uiState) {
              case UiState.valid_phone:
                return (
                  <>
                    <h2>驗證手機</h2>

                    <div className="field">
                      <label>手機號碼</label>
                      <Ant.Input
                        placeholder="0912345678"
                        maxLength={10}
                        value={phone}
                        onChange={(e) => {
                          setError('');
                          setPhone(e.target.value);
                        }}
                      />
                    </div>

                    <BottomBar>
                      <Ant.Button
                        type="primary"
                        disabled={!phone}
                        loading={sendingOtp}
                        onClick={() => {
                          _requestPhoneOTP();
                        }}>
                        寄送驗證碼
                      </Ant.Button>
                      {error && <span className="error">{error}</span>}
                    </BottomBar>
                  </>
                );
              case UiState.validating:
                return (
                  <>
                    <h2>已寄送驗證簡訊</h2>
                    <div style={{marginBottom: 10, color: '#999'}}>
                      請至手機 {phone}{' '}
                      查看是否收到驗證碼，若沒有收到驗證碼，可點選按鈕再次寄送。{' '}
                    </div>

                    <div className="field">
                      <label>驗證碼</label>
                      <Ant.Input
                        placeholder="六位數驗證碼"
                        maxLength={6}
                        value={code}
                        onChange={(e) => {
                          setError('');
                          setCode(e.target.value);
                        }}
                      />
                    </div>
                    <div></div>

                    <BottomBar>
                      <div>
                        <Ant.Button
                          loading={sendingOtp}
                          disabled={countdown !== null && countdown > 0}
                          onClick={() => _requestPhoneOTP()}
                          style={{marginRight: 10}}>
                          重寄驗證碼{countdown ? `(${countdown})` : ''}
                        </Ant.Button>

                        <Ant.Button
                          type="primary"
                          loading={loading}
                          onClick={_validateOtp}>
                          驗證
                        </Ant.Button>
                      </div>
                      {error && <span className="error">{error}</span>}
                    </BottomBar>
                  </>
                );
              case UiState.registering:
                return (
                  <>
                    <h2>輸入電子信箱及密碼</h2>
                    <div className="field">
                      <label>電子信箱</label>
                      <Ant.Input
                        value={values.email}
                        onChange={(e) =>
                          setValues({...values, email: e.target.value})
                        }
                      />
                      <span style={{color: 'grey', fontSize: 14, marginTop: 5}}>
                        *
                        訂單成立及電子發票是以email通知，請務必填入正確的email帳號，以免未收到訂單資訊。
                      </span>
                    </div>
                    <div className="field">
                      <label>
                        密碼
                        <span
                          style={{color: 'grey', fontSize: 14, marginLeft: 5}}>
                          (請輸入八碼以上的任意字元)
                        </span>
                      </label>
                      <Ant.Input
                        type="password"
                        value={values.password}
                        onChange={(e) =>
                          setValues({...values, password: e.target.value})
                        }
                      />
                    </div>

                    <div className="field">
                      <label>確認密碼</label>
                      <Ant.Input
                        type="password"
                        value={values.password2}
                        onChange={(e) =>
                          setValues({...values, password2: e.target.value})
                        }
                      />
                    </div>

                    <BottomBar>
                      <Ant.Button
                        type="primary"
                        loading={loading}
                        onClick={_handleRegister}>
                        註冊{' '}
                      </Ant.Button>
                      {error && <span className="error">{error}</span>}
                      <div className="hint">
                        註冊時，同步接受隱私權政策與使用條款
                      </div>
                    </BottomBar>
                  </>
                );
              case UiState.registered:
                return (
                  <Ant.Result
                    title="註冊成功!"
                    subTitle="手機驗證為您的會員帳號。請至會員中心修改您的姓名、生日、地址等必填欄位，加速購買流程"
                    status="success"
                    extra={[
                      <Ant.Button
                        type="primary"
                        loading={loading}
                        onClick={() => {
                          _openLoginModal();
                        }}>
                        登入
                      </Ant.Button>,
                    ]}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: var(--content-max-width);
  padding: 0px var(--content-padding);
  margin: 0 auto;
  width: 100%;
  padding-top: 45px;
  /* overwrite antd step style */
  & .rev-steps {
    max-width: 800px;
    &
      .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after,
    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: #d2d2d2;
    }
  }
  @media screen and (max-width: ${BREAK_POINTS.mobile}px) {
    & .rev-steps {
      & .ant-steps-item-title {
        width: 0px;
      }
      &
        .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        color: transparent;
      }
      & .ant-steps-item-icon {
        margin-right: 0px;
      }
    }
  }
  & > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 130px;
    & h3 {
      font-size: 34px;
      line-height: 48px;
      font-weight: bold;
      margin-bottom: 25px;
      color: #595757;
    }
    & h2 {
      font-size: 22px;
      margin-bottom: 25px;
      color: #595757;
    }
    & > .content {
      width: 100%;
      max-width: 800px;
    }
    & .error {
      color: #ff4d4f;
      font-size: 14px;
      margin-top: 5px;
    }
    & .field {
      margin-bottom: 20px;
    }
  }
`;

const BottomBar = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  & > .hint {
    font-size: 14px;
    color: grey;
    margin-top: 5px;
  }
`;

export default RegisterPage;
